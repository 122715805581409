<template>
  <layout>
    <div class="reports-page">
      <b-jumbotron v-if="!generated" header="Отчет">
        <label for="example-datepicker">Дата начала</label>
        <b-form-datepicker
          v-model="dateFrom"
          class="mb-2"
          size="lg"
          style="width: 500px"
          placeholder="Дата не выбрана"
        ></b-form-datepicker>

        <label for="example-datepicker">Дата окончания</label>
        <b-form-datepicker
          v-model="dateTo"
          class="mb-2"
          size="lg"
          style="width: 500px"
          placeholder="Дата не выбрана"
        ></b-form-datepicker>

        <b-button variant="info" @click="generateReport"
          >Построить отчет</b-button
        >
      </b-jumbotron>

      <div v-if="generated">
        <b-jumbotron v-if="hasRecords" :header="title"> </b-jumbotron>

        <b-jumbotron v-if="!hasRecords" header="Данных нет"> </b-jumbotron>

        <b-table
          v-if="hasRecords"
          striped
          hover
          :items="records"
          :fields="fields"
        ></b-table>

        <b-button
          v-if="hasRecords"
          class="d-print-none"
          variant="primary"
          @click="printReport"
          >Напечатать</b-button
        >

        <b-button
          class="d-print-none"
          variant="info"
          @click="generated = false"
          style="margin-left: 8px"
          >Сформировать новый отчет</b-button
        >
      </div>
    </div>
  </layout>
</template>

<script>
import dayjs from 'dayjs'
import Api from '@/api/api'
import Layout from '@/components/Layout'

export default {
  name: 'Reports',
  components: { Layout },

  data() {
    return {
      dateTo: dayjs().format('YYYY-MM-DD'),
      dateFrom: dayjs().format('YYYY-MM-DD'),
      generated: false,
      records: [],
      fields: [
        {
          key: 'plateNumber',
          label: 'Номер ТС',
        },
        {
          key: 'dateIn',
          label: 'Дата въезда',
          sortable: false,
        },
        {
          key: 'dateOut',
          label: 'Дата выезда',
          sortable: false,
        },
        {
          key: 'clientType',
          label: 'Клиент/Абонемент',
          sortable: false,
        },
        {
          key: 'valetName',
          label: 'Сотрудник',
          sortable: false,
        },

      ],
    }
  },

  created() {
    const routeTargetDate = this.$route.params.targetDate
    if (routeTargetDate) {
      this.dateFrom = routeTargetDate
      this.generateReport()
    }
  },

  computed: {
    hasRecords({ records }) {
      return records.length > 0
    },

    dateFromString({ dateFrom }) {
      return new Date(dateFrom).toLocaleDateString('ru-RU')
    },

    dateToString({ dateTo }) {
      return  new Date(dateTo).toLocaleDateString('ru-RU')
    },

    title({ dateFromString, dateToString }) {
      return `Отчет с ${dateFromString} по ${dateToString}`
    },
  },

  methods: {
    async generateReport() {
      const { dateFrom, dateTo } = this

      try {
        this.generalError = ''
        const report = await Api.getRecords({ dateFrom, dateTo })
        this.dateFromShift = report.dateFromShift
        this.dateToShift = report.dateToShift

        const transformedRecords = this.transformToTable(report.records)
        this.records = transformedRecords

        this.generated = true
      } catch (e) {
        this.generalError = 'Данных нет'
        this.dateFromShift = null
        this.dateToShift = null
        this.records = []
        this.generated = true
      }
    },

    transformToTable(records) {
      const transformed = records.map((record, index) => {
        return {
          plateNumber: record.plateNumber,
          dateIn:
            record.moveType === 'in'
              ? new Date(record.eventTime).toLocaleString('ru-RU')
              : '',
          dateOut:
            record.moveType === 'out'
              ? new Date(record.eventTime).toLocaleString('ru-RU')
              : '',
          clientType:
            record.clientType === 'subscription' ? 'Абонемент' : 'Клиент',
          id: record._id,
          clientTypeValue: record.clientType,
          moveTypeValue: record.moveType,
          valetName: record.shift ? record.shift.valetName : '-'
        }
      })

      return transformed
    },

    printReport() {
      window.print()
    },
  },
}
</script>

<style scoped>
.reports-page {
  padding: 16px;
}
</style>
