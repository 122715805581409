import { HTTP } from '@/services/HttpService'

export default {
  async login(password: string): Promise<string> {
    const params = { password }

    const result = await HTTP.post('/auth', params)
    return result.data.token
  },

  async getStatus(): Promise<any> {
    const result = await HTTP.get('/status')
    return result.data
  },

  async stopShift(): Promise<any> {
    const result = await HTTP.post('/shift/stop')
    return result.data
  },

  async startShift(name: string): Promise<any> {
    const result = await HTTP.post('/shift/start', { name })
    return result.data
  },

  async createRecord(params: any): Promise<any> {
    const result = await HTTP.post('/record', params)
    return result.data
  },

  async saveRecord(params: any): Promise<any> {
    const result = await HTTP.put('/records', params)
    return result.data
  },

  async getRecord(id: string): Promise<any> {
    const result = await HTTP.get(`/record/${id}`)
    return result.data
  },

  async deleteRecord(id: string): Promise<any> {
    const result = await HTTP.delete(`/records?id=${id}`)
    return result.data
  },

  async getRecords({ dateFrom, dateTo } : any) {
    const result = await HTTP.get(`/records?dateFrom=${dateFrom}&dateTo=${dateTo}`)
    return result.data
  },

  async getStaffList() {
    const result = await HTTP.get(`/staff`)
    return result.data.list
  },

  async setStaffList(params: any) {
    const result = await HTTP.post(`/staff`, params)
    return result.data
  },
}
