import axios from 'axios'
import * as Config from '@/.env'

export const HTTP = axios.create({
  baseURL: `${Config.API_URL}/api/valet`,
})

HTTP.interceptors.request.use(async function (config) {
  const token = getBearerToken()

  if (token) config.headers.common['Authorization'] = `Bearer ${token}`

  return config
})

HTTP.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)

export function setAuthToken(token: string) {
  localStorage.setItem('token', token)
}

export function isAuth() {
  const token = localStorage.getItem('token')
  if (token) return true
  return false
}

export function logoutUser() {
  localStorage.removeItem('token')
}

function getBearerToken() {
  const token = localStorage.getItem('token')
  if (token) return token
  return null
}
