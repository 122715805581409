<template>
  <div class="login">
    <h1>AIRPARKING24.RU</h1>

    <b-alert v-if="generalError" show variant="danger">{{
      generalError
    }}</b-alert>
    <div class="login-form">
      <b-form-input
        v-model="password"
        placeholder="Пароль"
        size="lg"
      ></b-form-input>
      <b-button
        style="margin-left: 8px"
        variant="primary"
        size="lg"
        @click="tryLogin"
        >Войти</b-button
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
.login {
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-form {
  max-width: 600px;
  width: 400px;
  display: flex;
}
</style>

<script>
import API from '@/api/api'
import { setAuthToken } from '@/services/HttpService'

export default {
  name: 'Login',

  data() {
    return {
      generalError: '',
      password: '',
    }
  },

  methods: {
    async tryLogin(e) {
      e.preventDefault()
      this.generalError = ''

      try {
        const token = await API.login(this.password)
        setAuthToken(token)
        this.$router.push({ name: 'Shift' })
      } catch (e) {
        this.generalError =
          'Ошибка при попытке войти в систему. Проверьте правильность введенного пароля'
      }
    },
  },
}
</script>
