import { isAuth } from '@/services/HttpService'

/**
 * Check access permission to auth routes
 */
export function checkAccessMiddleware(to: any, from: any, next: any) {
  const authed = isAuth()
  const isAuthRoute = to.matched.some((item: any) => item.meta.isAuth)

  if (isAuthRoute && authed) return next()
  if (isAuthRoute) return next({ name: 'Login' })

  next()
}
