<template>
  <div class="container-xl special-form">
    <b-img src="/form-header.jpg" rounded fluid />
    <h5 class="mt-4">Форма получения спец. тарифа</h5>
    <a href="http://www.airparking24.ru" target="_blank">AIRPARKING24.RU</a>

    <b-alert v-if="submitted" class="mt-4" variant="success" show
      >Форма успешно отправлена</b-alert
    >

    <b-form v-if="!submitted" class="mt-4" @submit="onSubmit" @reset="onReset">
      <!-- ФИО -->
      <b-card header-html="<b>Фамилия, имя и отчество</b>">
        <b-form-group
          label-for="name-input"
          description="Пример: Иванов Алексей Петрович"
        >
          <b-form-input
            id="name-input"
            v-model="form.name"
            type="text"
            placeholder="Введите ФИО"
            required
            с
          ></b-form-input>
        </b-form-group>
      </b-card>

      <!-- Телефон -->
      <b-card header-html="<b>Номер телефона (Parcoin)</b>" class="mt-4">
        <b-form-group
          label-for="tel-input"
          description="Пример: +7 902 222 01 01"
        >
          <b-form-input
            id="tel-input"
            v-model="form.tel"
            type="text"
            placeholder="Введите номер телефона"
            required
            size="lg"
          ></b-form-input>
        </b-form-group>
      </b-card>

      <!-- Электронная почта" -->
      <b-card header-html="<b>Электронная почта</b>" class="mt-4">
        <b-form-group
          label-for="email-input"
          description="Пример: john.smith@mail.ru"
        >
          <b-form-input
            id="email-input"
            v-model="form.email"
            type="email"
            placeholder="Введите почту"
            required
            size="lg"
          ></b-form-input>
        </b-form-group>
      </b-card>

      <!-- Гос. номера машин" -->
      <b-card header-html="<b>Номера автомобилей</b>" class="mt-4">
        <b-form-group
          label-for="plates-number-input"
          description="Введите номер авто и нажмите ENTER"
        >
          <b-form-tags
            v-model="form.platesNumber"
            tag-class="plate-number-tags"
            size="lg"
            tag-variant="info"
            duplicate-tag-text="Номер уже добавлен"
            add-button-text="Добавить"
            input-id="plates-number-input"
            placeholder=""
            required
          ></b-form-tags>
        </b-form-group>
      </b-card>

      <!-- Файлы -->
      <b-card
        header-html="<b>Фото документа  <br /> - лётное удостоверение или <br />- удостоверение сотрудника</b>"
        class="mt-4"
      >
        <b-form-group label-for="file1-input">
          <b-form-file
            v-model="form.file1"
            if="file1-input"
            placeholder="Файл 1"
            required
          ></b-form-file>

          <b-form-file
            v-model="form.file2"
            class="mt-4"
            if="file2-input"
            placeholder="Файл 2"
          ></b-form-file>
        </b-form-group>
      </b-card>

      <div>
        <b-form-checkbox
          class="mt-4"
          id="agree-input"
          v-model="form.isAgree"
          value="true"
          unchecked-value="false"
          required
          size="lg"
        >
          С правилами применения льготного тарифа ознакомлен и согласен <br />
          Правила доступны по адресу:
          <a href="https://airparking24.ru/special-form-rules.pdf"
            >https://airparking24.ru/special-form-rules.pdf</a
          >
        </b-form-checkbox>
      </div>

      <b-alert v-if="error" class="mt-4" variant="danger" show>{{
        error
      }}</b-alert>

      <b-button class="mt-4" type="submit" variant="primary"
        >Отправить</b-button
      >
    </b-form>
    <!-- </b-card> -->
  </div>
</template>

<script>
import axios from 'axios'
// const API_URL = 'http://localhost:4000/api/special-form/submit'
const API_URL = 'https://valet.airparking24.ru/api/special-form/submit'

// const API_URL1 = 'http://localhost:4000/api/book'

export default {
  data() {
    return {
      form: {
        email: '',
        tel: '',
        name: '',
        platesNumber: [],
        file1: null,
        file2: null,
      },
      show: true,
      error: false,
      submitted: false,
    }
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault()
      this.error = false

      if (this.form.file1 && this.form.file1.size > 5242880) {
        this.error = 'Размер фото превышает 5 Мб :-('
        return
      }

      if (this.form.file2 && this.form.file2.size > 5242880) {
        this.error = 'Размер фото превышает 5 Мб :-('
        return
      }

      if (this.form.platesNumber.length === 0) {
        this.error = 'Не указаны номера автомобилей :-('
        return
      }

      if (!this.form.isAgree) {
        this.error =
          'Необходимо выразить согласание с правилами применения льготного тарифа'
        return
      }

      const bodyData = new FormData()
      bodyData.append('email', this.form.email)
      bodyData.append('tel', this.form.tel)
      bodyData.append('name', this.form.name)
      bodyData.append('platesNumber', this.form.platesNumber)

      bodyData.append('file1', this.form.file1)
      bodyData.append('file2', this.form.file2)

      try {
        await axios({
          method: 'post',
          url: API_URL,
          data: bodyData,
        })

        this.submitted = true
      } catch (error) {
        this.error = `Ошибка при отправке формы :-( ${error.message}`
      }
    },
    onReset(event) {
      event.preventDefault()
      // Reset our form values
      this.form.email = ''
      this.form.name = ''
      this.form.food = null
      this.form.checked = []
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
  },
}
</script>

<style>
.special-form {
  padding: 16px;
  max-width: 900px;
}

.plate-number-tags {
  font-size: 1.2rem;
}

.plate-number-tags > .b-form-tag-remove {
  margin-left: 16px !important;
  padding: 8px;
}
</style>
