<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="primary">
      <b-navbar-brand href="#">AIRPARKING24.RU</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="#" @click="goShift">Смена</b-nav-item>
          <b-nav-item href="#" @click="goReport">Отчеты</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em>Дополнительно</em>
            </template>
            <b-dropdown-item href="#" @click="logoutUser"
              >Выйти</b-dropdown-item
            >
              <b-dropdown-item href="#" @click="goStuffList"
              >Список сотрудников</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <slot />
  </div>
</template>

<script>
import { logoutUser } from '@/services/HttpService'
export default {
  methods: {
    logoutUser() {
      logoutUser()
      this.$router.push({ name: 'Login' })
    },

    goShift() {
      this.$router.push({ name: 'Shift' })
    },

    goReport() {
      this.$router.push({ name: 'Reports' })
    },

    goStuffList() {
      this.$router.push({ name: 'StaffList' })
    },
  },
}
</script>

<style lang="scss" scoped></style>
