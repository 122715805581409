import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '../views/Login.vue'
import Shift from '../views/Shift.vue'
import StaffList from '../views/StaffList.vue'
import Reports from '../views/Reports.vue'
import NotFound from '../views/NotFound.vue'
import SpecialForm from '../views/SpecialForm.vue'

import { checkAccessMiddleware } from '@/router/middlewares/checkAccessMiddleware'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Shift,
    meta: { isAuth: true },
  },
  {
    path: '/shift',
    name: 'Shift',
    component: Shift,
    meta: { isAuth: true },
  },
  {
    path: '/report',
    name: 'Reports',
    component: Reports,
    meta: { isAuth: true },
  },
  {
    path: '/report/:targetDate',
    name: 'ReportsDate',
    component: Reports,
    meta: { isAuth: true },
  },
  {
    path: '/staff',
    name: 'StaffList',
    component: StaffList,
    meta: { isAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/special',
    name: 'SpecialForm',
    component: SpecialForm,
  },
  {
    path: '*',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(checkAccessMiddleware)

export default router
