<template>
  <layout>
    <div class="staff-list-page">
      <b-jumbotron header="Список сотрудников"> </b-jumbotron>

      <list-staff :list="list" mode="edit" @remove="remove" />

      <b-button style="margin-top: 16px" v-if="!addMode" @click="newStaff">Новый сотрудник</b-button>

      <div v-if="addMode">
        <b-form-input
          style="margin-bottom: 16px"
          v-model="staffName"
          placeholder="Введите имя"
        ></b-form-input>
        <b-button @click="add" variant="info">Добавить</b-button>
        <b-button @click="cancelAdd" style="margin-left: 8px">Отмена</b-button>
      </div>
    </div>
  </layout>
</template>

<script>
import Api from '@/api/api'
import Layout from '@/components/Layout'
import ListStaff from '../components/ListStaff.vue'

export default {
  name: 'StaffList',
  components: { Layout, ListStaff },

  data() {
    return {
      generalError: null,
      list: [],
      addMode: false,
      staffName: '',
    }
  },

  created() {
    this.fetchStaffList()
  },

  methods: {
    async fetchStaffList() {
      try {
        this.generalError = ''
        const list = await Api.getStaffList()
        this.list = list
      } catch (e) {
        this.generalError = e
      }
    },

    newStaff() {
      this.staffName = ''
      this.addMode = true
    },

    async add() {
      this.list.push({ name: this.staffName })
      await Api.setStaffList({ list: this.list })
      this.addMode = false
    },

    cancelAdd() {
      this.addMode = false
    },

    async remove(user) {
      const list = this.list.filter((item) => item.name !== user.name)
      this.list = list

      await Api.setStaffList({ list: this.list })
    },
  },
}
</script>

<style scoped>
.staff-list-page {
  padding: 16px;
}
</style>
