<template>
  <div>
    <h3 v-if="list.length === 0">Список сотрудников пуст</h3>

    <b-list-group>
      <b-list-group-item
        v-for="user in list"
        :key="user.name"
        >{{ user.name }}
        <b-button
          style="margin-left: 12px"
          v-if="mode === 'edit'"
          size="sm"
          pill
          variant="outline-secondary"
          @click="remove(user)"
          >Удалить</b-button
        >
        <b-button
          v-if="mode === 'view'"
          style="margin-left: 12px"
          size="sm"
          pill
          variant="outline-secondary"
          @click="select(user)"
          >Начать смену</b-button
        >
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
export default {
  name: 'ListStaff',

  props: {
    list: Array,
    mode: {
      type: String,
      default: 'view',
    },
  },

  methods: {
    remove(user) {
      this.$emit('remove', user)
    },

    select(user) {
      this.$emit('select', user)
    },
  },
}
</script>

<style lang="scss" scoped></style>
