<template>
  <layout>
    <div class="shift-page">
      <div v-if="activeShift">
        <b-jumbotron :header="getShiftHeader">
          <p>Сотрудник: {{ activeShift.valetName }}</p>
          <b-button variant="danger" @click="finishShift"
            >Завершить смену</b-button
          >

          <b-button
            style="margin-left: 16px"
            variant="info"
            @click="generateReport"
            >Сформировать отчет</b-button
          >
        </b-jumbotron>
      </div>

      <div v-if="!activeShift">
        <b-jumbotron header="Смена не начата">
          <b-alert v-if="generalError" show variant="danger">{{
            generalError
          }}</b-alert>

          <template>
            <!-- <b-button variant="primary" @click="startShift"
              >Начать смену</b-button
            > -->

            <list-staff v-if="!activeShift" mode="view" :list="list" @select="selectValet" />
          </template>
        </b-jumbotron>
      </div>

      <div v-if="mode === 'create'">
        <create-record
          @switchToView="switchToView"
          @recordCreated="fetchStatus"
          :selected="selected"
        />
      </div>

      <div v-if="mode === 'view' && activeShift">
        <b-table
          striped
          hover
          :items="records"
          :fields="fields"
          @row-clicked="openRecord"
        ></b-table>
        <b-button v-if="activeShift" variant="primary" @click="addRecord"
          >Добавить запись</b-button
        >
      </div>
    </div>
  </layout>
</template>

<script>
import dayjs from 'dayjs'
import Layout from '@/components/Layout'
import CreateRecord from '@/components/CreateRecord'
import Api from '@/api/api'
import ListStaff from '../components/ListStaff.vue'

export default {
  name: 'Shift',
  components: { Layout, CreateRecord, ListStaff },

  data() {
    return {
      fields: [
        {
          key: 'plateNumber',
          label: 'Номер ТС',
        },
        {
          key: 'dateIn',
          label: 'Дата въезда',
          sortable: false,
        },
        {
          key: 'dateOut',
          label: 'Дата выезда',
          sortable: false,
        },
        {
          key: 'clientType',
          label: 'Клиент/Абонемент',
          sortable: false,
        },
      ],
      activeShift: null,
      records: null,
      generalError: '',
      mode: 'view',
      selected: null,
      list: [],
    }
  },

  methods: {
    transformToTable(records) {
      const transformed = records.map((record, index) => {
        return {
          plateNumber: record.plateNumber,
          dateIn:
            record.moveType === 'in'
              ? new Date(record.eventTime).toLocaleString('ru-RU')
              : '',
          dateOut:
            record.moveType === 'out'
              ? new Date(record.eventTime).toLocaleString('ru-RU')
              : '',
          clientType:
            record.clientType === 'subscription' ? 'Абонемент' : 'Клиент',
          id: record._id,
          clientTypeValue: record.clientType,
          moveTypeValue: record.moveType,
        }
      })

      return transformed
    },

    async fetchStatus() {
      try {
        this.generalError = ''
        const status = await Api.getStatus()
        this.activeShift = status.activeShift
        this.records = this.transformToTable(status.records)
        this.mode = 'view'
        this.selected = null
      } catch (e) {
        this.generalError = e
      }
    },

    openRecord(item) {
      this.selected = item
      this.mode = 'create'
    },

    async finishShift() {
      try {
        this.generalError = ''
        const status = await Api.stopShift()
        if (status) {
          this.activeShift = status.activeShift
          this.mode = 'view'
        }
      } catch (e) {
        this.generalError = e
      }
    },

    showValetList() {
      this.mode = 'selectValet'
    },

    selectValet(user) {
      this.startShift(user)
    },

    async startShift(user) {
      try {
        this.generalError = ''
        const status = await Api.startShift(user.name)
        this.fetchStatus()
        
        // if (status) this.activeShift = status.activeShift
      } catch (e) {
        this.generalError = e
      }
    },

    async addRecord() {
      try {
        this.mode = 'create'
        const status = await Api.startShift()
        if (status) this.activeShift = status.activeShift
      } catch (e) {
        this.generalError = e
      }
    },

    switchToView() {
      this.mode = 'view'
      this.selected = null
    },

    generateReport() {
      const targetDate = dayjs(this.activeShift.startDate).format('YYYY-MM-DD')
      this.$router.push({ name: 'ReportsDate', params: { targetDate } })
    },

    async fetchStaffList() {
      try {
        this.generalError = ''
        const list = await Api.getStaffList()
        this.list = list
      } catch (e) {
        this.generalError = e
      }
    },
  },

  async created() {
    this.fetchStatus()
    this.fetchStaffList()
  },

  computed: {
    getShiftHeader({ activeShift }) {
      if (activeShift)
        return `Смена ${new Date(activeShift.startDate).toLocaleDateString(
          'ru-RU'
        )}`

      return 'Смена не начата'
    },
  },
}
</script>

<style lang="scss" scoped>
.shift-page {
  padding: 16px;
}
</style>
