<template>
  <div class="home">NOT FOUND</div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'NotFound',
}
</script>
