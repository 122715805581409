<template>
  <div class="create-record">
    <h1>{{ title }}</h1>

    <b-alert v-if="generalError" show variant="danger">{{
      generalError
    }}</b-alert>

    <b-form-group
      style="margin-top: 16px"
      size="lg"
      id="input-group-2"
      label="Номер машины"
      label-for="input-2"
    >
      <b-form-input
        v-model="plateNumber"
        size="lg"
        id="input-2"
        placeholder="Введите номер машины"
        :state="plateNumber ? null : false"
        required
      ></b-form-input>
    </b-form-group>

    <b-form-group
      style="margin-top: 16px"
      size="lg"
      label="Въезд или выезд"
      v-slot="{ ariaDescribedby }"
    >
      <b-form-radio-group
        size="lg"
        v-model="inOutSelected"
        :options="inOutList"
        :aria-describedby="ariaDescribedby"
        name="in-out-radio"
      ></b-form-radio-group>
    </b-form-group>

    <b-form-group
      style="margin-top: 16px"
      size="lg"
      label="Клиент или абонемент"
      v-slot="{ ariaDescribedby }"
    >
      <b-form-radio-group
        size="lg"
        v-model="clientTypeSelected"
        :options="clientTypeList"
        :aria-describedby="ariaDescribedby"
        name="client-type-radio"
      ></b-form-radio-group>
    </b-form-group>

    <div style="margin-top: 32px">
      <b-button variant="primary" @click="addRecord" :disabled="!plateNumber">{{
        saveText
      }}</b-button>
      <b-button
        variant="secondary"
        style="margin-left: 16px"
        @click="$emit('switchToView')"
        >Отмена</b-button
      >
      <b-button
        v-if="selected"
        variant="danger"
        style="margin-left: 16px"
        @click="deleteRecord"
        >Удалить запись</b-button
      >
    </div>
  </div>
</template>

<script>
import Api from '@/api/api'

export default {
  props: {
    selected: Object,
  },
  data() {
    return {
      plateNumber: '',
      inOutList: [
        { text: 'Въезд', value: 'in' },
        { text: 'Выезд', value: 'out' },
      ],
      clientTypeList: [
        { text: 'Клиент', value: 'client' },
        { text: 'Абонемент', value: 'subscription' },
      ],
      inOutSelected: 'in',
      clientTypeSelected: 'client',
      generalError: null,
    }
  },

  computed: {
    title({ selected }) {
      if (selected) return 'Редактирование записи'
      return 'Добавление записи'
    },
    saveText({ selected }) {
      if (selected) return 'Сохранить'
      return 'Создать запись'
    },
  },

  created() {
    if (this.selected) {
      this.plateNumber = this.selected.plateNumber
      this.clientTypeSelected = this.selected.clientTypeValue
      this.inOutSelected = this.selected.moveTypeValue
    }
  },

  methods: {
    async deleteRecord() {
      try {
        this.generalError = ''

        const result = await Api.deleteRecord(this.selected.id)
        this.$emit('recordCreated')
      } catch (e) {
        this.generalError = e
      }
    },

    async addRecord() {
      try {
        this.generalError = ''

        const params = {
          id: this.selected ? this.selected.id : null,
          plateNumber: this.plateNumber,
          clientType: this.clientTypeSelected,
          moveType: this.inOutSelected,
        }

        if (this.selected) {
          await Api.saveRecord(params)
        } else {
          await Api.createRecord(params)
        }

        this.$emit('recordCreated')
      } catch (e) {
        this.generalError = e
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.create-record {
  padding: 16px;
  max-width: 600px;
}
</style>
